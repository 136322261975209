<template>
	<div>
		<v-row class="mx-0" v-if="ready === 0">
			<v-col class="">
				<v-menu bottom right rounded="lg">
					<template v-slot:activator="{ on }">
						<v-btn small outlined v-on="on" class="rounded-lg">
							<span class="caption-1">{{ type_to_label[type] }}</span>
							<v-icon right>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item @click="type = 'days'">
							<v-list-item-content class="caption-1">{{ $tc('single_trad.Calendar.day', 1) }}
							</v-list-item-content>
						</v-list-item>
						<v-list-item @click="type = 'weeks'">
							<v-list-item-content class="caption-1">{{ $tc('single_trad.Calendar.week', 1) }}
							</v-list-item-content>
						</v-list-item>
						<v-list-item @click="type = 'months'">
							<v-list-item-content class="caption-1">{{ $tc('single_trad.Calendar.month', 1) }}
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-col>
		</v-row>
		<v-divider class="mt-1 cell4" v-if="ready === 0" />
		<div v-if="ready === 0" style="position: relative;" ref="parent">
			<v-row class="mx-0 flex-nowrap" no-gutters style="overflow: auto auto; min-height: 400px" ref="viewport">
				<v-col
						class="paragraph cell2"
						cols="auto"
						style="position: sticky; left: 0; border-right: 1px solid var(--v-cell4-base) !important; z-index: 2; max-width: 240px"
				>
					<v-row class="mx-0" no-gutters>
						<v-col
								class="pa-3"
								style="height: 45px; border-bottom: 1px solid var(--v-cell4-base)"
						>
							{{ $tc('single_trad.Gantt.tasks', items.length, {nmb : items.length}) | firstCapital }}
						</v-col>
					</v-row>
					<v-row
							@click="scrollView(item)"
							class="mx-0"
							v-for="(item, i) in items"
							:key="i"
							no-gutters
							:style="{minHeight: (item_height) + 'px'}"
							style="position: relative"
							@mouseenter="$set(items[i], '__hover', true)"
							@mouseleave="$set(items[i], '__hover', false)"
					>
						<v-col class="d-flex px-0" style="justify-content: center; flex-direction: column">
							<div :style="item.__hover ? {backgroundColor: $tools.transparentize('cell4', 0.3, $vuetify)}:{}" class="pl-3 pr-1 transition-cubic">
								<v-row no-gutters :style="{height: item_height +'px'}" align="center" class="cursor-pointer">
									<v-col class="pr-2">
										<div class="text-truncate" :title="item.display_name">{{ item.display_name }}</div>
									</v-col>
									<v-spacer />
									<v-col cols="auto" v-if="item.tasks.length > 0" @click.stop="item.__open = !item.__open">
										<v-icon size="20" :class="item.__open ? 'transition-rotate':''">mdi-chevron-down</v-icon>
									</v-col>
								</v-row>
								<v-expand-transition>
									<div v-show="item.__open">
										<div
												v-for="task in item['tasks']"
												:key="task.id"
												class="d-flex"
												style="align-items: center"
												:style="{height: item_height +'px'}"
										>
											<div>
												{{ task.display_name }}
											</div>
										</div>
									</div>
								</v-expand-transition>
							</div>
						</v-col>
						<v-divider style="position: absolute; width: 100%; bottom: 0;" />
					</v-row>
				</v-col>

				<v-col style="position: relative;" @wheel="(e) => zoomWheel(e)" @mouseleave="tool_tip = {}" class="cell">
					<div style="position: absolute; display: flex;" class="fill-height" ref="dates">
						<div v-for="(date,i) in dates[type]" :key="i" class="text-center" :class="$vuetify.theme.dark ? 'cell2':''">
							<div style="border-bottom: 1px solid var(--v-cell4-base) !important; height: 45px; box-shadow: -1px 0 0 0.4px var(--v-cell4-base) inset" class="pa-3 paragraph cell2">{{ date.label }}</div>
							<v-row class="mx-0 flex-nowrap" no-gutters style="height: calc(100% - 45px)">
								<v-col
										@mousemove="(e) => mouseEnter(e, sub, date)"
										@mouseleave="tool_tip = {}"
										v-for="sub in date.sub"
										:key="sub"
										:style="[{ width: unit_width + 'px', minWidth: unit_width + 'px', maxWidth: unit_width + 'px' }, sub < date.sub ? {borderRight: '1px solid var(--v-cell3-base) !important'}:{borderRight: '1px solid var(--v-cell4-base) !important'},	compareToToday(date.start_date, sub) ? tool_tip.sub === sub && tool_tip.date === date ? {backgroundColor: $tools.transparentize('primary', 0.3, $vuetify)} : {backgroundColor: $tools.transparentize('primary', 0.15, $vuetify)} : tool_tip.sub === sub && tool_tip.date === date ? {backgroundColor: $tools.transparentize('cell3', 0.8, $vuetify)} : {}]"
										class="transition-cubic"
								></v-col>
							</v-row>
						</div>
					</div>

					<div class="mx-0 flex-nowrap paragraph" no-gutters style="margin-top: 45px; z-index: 2" :style="{width: dates_width + 'px'}">
						<template v-for="(item, i) in items">
							<v-row
									@mouseenter="$set(items[i], '__hover', true)"
									@mouseleave="$set(items[i], '__hover', false)"
									:key="i"
									style="position: relative; pointer-events: none"
									class="mx-0 flex-nowrap"
									no-gutters
							>
								<div
										:style="item.__hover ? {backgroundColor: $tools.transparentize('cell3', 0.8, $vuetify)}:{}"
										style="position: absolute; width: 100%; height: 100%; pointer-events: none"
										class="transition-cubic"
								></div>
								<v-col
										style="width: 100%; flex-direction: column;"
										class="d-flex transition-cubic"
										:style="{minHeight: item_height + 'px', marginLeft : item._position[type] * unit_width + 'px', maxWidth: item._width[type] * unit_width + 'px'}"
								>
									<div :style="{height: item_height +'px'}" class="d-flex" style="align-items: center; width: 100%;">
										<div
												@click="pevents.notify('fast-edit', items[i])"
												class="primary px-2 text-truncate cursor-pointer elevation-2 white--text"
												style="width: 100%; z-index: 1; pointer-events: all"
												:class="item.end === null ? item.start === null ? 'tile' : 'rounded-l-lg': 'rounded-lg'"
												:title="item.display_name"
										>
											{{ item.display_name }}
										</div>
									</div>
									<v-expand-transition>
										<div v-show="item.__open">
											<div
													v-for="(task,j) in item['tasks']"
													:key="task.id"
													class="d-flex transition-cubic"
													style="align-items: center"
													:style="{minHeight: item_height + 'px', marginLeft : (task._position[type] * unit_width) - (item._position[type] * unit_width) + 'px', width: task._width[type] * unit_width + 'px'}"
											>
												<div
														class="secondary px-2 cursor-pointer text-truncate elevation-2 white--text"
														style="z-index: 1; width: 100%; pointer-events: all"
														:class="task.end === null ? task.start === null ? 'tile' : 'rounded-l-lg': 'rounded-lg'"
														:title="task.display_name"
														@click="fetchTaskFields(items[i].tasks[j], item.id)"
												>
													{{ task.display_name }}
												</div>
											</div>
										</div>
									</v-expand-transition>
								</v-col>
								<v-divider style="position: absolute; width: 300%; bottom: 0; left: 0;" />
							</v-row>
						</template>
					</div>
				</v-col>
				<v-fade-transition>
					<div
							v-show="tool_tip.x"
							class="pa-2 cell rounded-lg elevation-2 transition-cubic text-center"
							style="position: fixed; z-index: 2; pointer-events: none; min-width: 90px; min-height: 28px"
							:style="{top: tool_tip.y + 'px', left: tool_tip.x + 'px'}"
					>
						{{ tool_tip.x ? labelDate(tool_tip.date, tool_tip.sub) : '' }}
					</div>
				</v-fade-transition>
			</v-row>
		</div>
		<div class="pa-8 text-center" v-else>
			<v-progress-circular indeterminate size="40" color="primary"/>
		</div>

		<Dialog
				v-model="edit_dialog"
				:title="item2edit.id ? $t('single_trad.Dialog.fast_edit') : $t('single_trad.Dialog.fast_create')"
				width="500"
				@close="events.notify('close-fast'); edit_dialog = false"
		>
			<template v-slot:actions v-if="mode === 'fast-edition'">
				<Button iconVal="mdi-eye" icon dark :click="() => $router.push('/dashboard/' + path + '/' + item2edit.id)" />
				<Button iconVal="mdi-delete" icon dark :click="() => { item2delete = item2edit; confirm_dialog = true }" />
			</template>
			<template v-slot:content>
				<div class="pa-8 text-center" v-if="loading">
					<v-progress-circular indeterminate size="40" color="primary"/>
				</div>
				<v-fade-transition v-else>
					<FieldGroup
							v-show="edit_dialog"
							:show="edit_dialog"
							class="px-3"
							v-model="item2edit"
							:fields="$tools.filterObject(all_fields, e => !e.read_only)"
							:mode="mode"
							:path="edit_path"
							:pevents="events"
							:pathId="item2edit ? item2edit.id : ''"
							@close="edit_dialog = false"
							@saved="(e) => savedTask(e)"
							@fail="$store.dispatch('snackbar/error', $tc('snackbar.FieldGroup.createAll.fail', 1))"
							@failSaved="$store.dispatch('snackbar/error', $tc('snackbar.EntityField.saveChanges.fail', 1))"
					/>
				</v-fade-transition>
			</template>
		</Dialog>
	</div>
</template>

<script>
	export default {
		name: 'Gantt',
		props: ['path', 'pevents', 'routeParent'],
		components: {
			Dialog: () => import('@/components/ui/Dialog.vue'),
			FieldGroup: () => import('@/components/FieldGroup.vue')
		},
		data() {
			return {
				type: "weeks",
				type_to_label: {
					months: this.$tc('single_trad.Calendar.month', 1),
					weeks: this.$tc('single_trad.Calendar.week', 1),
					days: this.$tc('single_trad.Calendar.day', 1)
				},
				items: [],
				dates: [],
				ready: -1,
				unit_width: 50,
				item_height: 40,
				date_units_ref: {'days': 'hours', 'weeks': 'days', 'months': 'days', 'years': 'months'},
				dates_width: 0,
				tool_tip: {},
				edit_dialog: false,
				item2edit: {},
				mode: null,
				all_fields: {},
				events: null,
				loading: false,
				edit_path: '',
				item2edit_id: null
			}
		},
		watch: {
			ready(val){
				if(val === 0){
					this.$nextTick(() => {
						this.dates_width = this.$refs.dates.clientWidth
					})
				}
			},
			type(val, old_val){
				if(old_val === 'days' && this.unit_width <= 20){
					this.unit_width = 20
				}

				setTimeout(() => {
					this.$nextTick(() => {
						this.dates_width = this.$refs.dates.clientWidth
					})
				}, 250)
			}
		},
		methods: {
			compareToToday(date, n){
				if (this.type === 'years')
					return this.$moment(date).add(n - 1, 'months').format('MMM YYYY') === this.$moment().format('MMM YYYY')
				if (this.type === 'months' || this.type === 'weeks')
					return this.$moment(date).add(n - 1, 'days').format('LL') === this.$moment().format('LL')
				if (this.type === 'days')
					return this.$moment(date).format('LL') === this.$moment().format('LL')
			},
			computeDateData(startDate, type){
				return {
					label:
						type === 'days' ? this.$moment(startDate).format('DD MMM YY'):
						type === 'weeks' ? this.$moment(startDate).weekday(0).format('DD') + '-' + this.$moment(startDate).weekday(6).format('DD MMM YY'):
						type === 'months' ? this.$moment(startDate).format('MMM YY'): this.$moment(startDate).format('YYYY'),
					start_date:
						type === 'days' ? this.$moment(startDate).format('YYYY-MM-DD') + ' 00:00':
						type === 'weeks' ? this.$moment(startDate).weekday(0).format('YYYY-MM-DD'):
						type === 'months' ? this.$moment(startDate).format('YYYY-MM') + '-01': this.$moment(startDate).format('YYYY') + '-01',
					sub:
						type === 'months' ? this.$moment(startDate).daysInMonth():
						type === 'days' ? 24:
						type === 'weeks' ? 7 : 12
				}
			},
			computeItemsDates(pasfirst){
				this.items.forEach((item, i) => {
					this.$set(this.items[i], '_position', this.daysFromStart('item', item.start, null, true))
					this.$set(this.items[i], '_width', this.daysFromStart('item', item.start, item.end, false))

					if (item.tasks ? item.tasks.length > 0 : false){
						item.tasks.forEach((task, j) => {
							this.$set(this.items[i].tasks[j], '_position', this.daysFromStart(item.id, task.start || item.start, null, true))
							this.$set(this.items[i].tasks[j], '_width', this.daysFromStart(item.id, task.start || item.start, task.end || item.end, false))
						})
					}
				})

				if(!pasfirst)
					this.ready--
			},
			daysFromStart(isItem, start, end, position){
				let obj = {}

				for (let key in this.date_units_ref){
					if (position){
						let width = this.$moment(start).diff(this.$moment(this.dates[key][0].start_date), this.date_units_ref[key])
						obj[key] = Math.abs(width)
					}
					else {
						let width = this.$moment(start).diff(this.$moment(end), this.date_units_ref[key])

						if (isNaN(width)){
							width = this.$moment().diff(this.$moment(start), this.date_units_ref[key])

							if(width < 0) {
								if(key === 'days')
									width = 24
								else
									width = 0
							}
						}

						obj[key] = (Math.abs(width) + 1)
					}
				}

				return obj
			},
			fetchEntityItems() {
				this.$wsc.getList(this.path, {}, items => {
					this.ready = items.length + 1
					this.items = items;

					this.items.forEach((item, i) => {
						this.$set(this.items[i], '__open', false)
						this.fetchEntityTasks(this.items[i])
					})

					this.initDates()
				},
				fail => {

				});
			},
			fetchTaskFields(item, event_id){
				this.edit_path = this.path + '/' + event_id + '/task'

				let route = {...this.$route}
				route.params.projectevent = ''+event_id

				this.$wsc.getOptions(this.$route.params, {}, this.edit_path, success => {
					this.item2edit = item
					this.item2edit_id = event_id
					this.all_fields = this.$tools.sortObject(success.fields, 'label')
					this.mode = 'fast-edition'
					this.loading = false
					this.edit_dialog = true
				}, fail => {

				})
			},
			fetchEntityTasks(item) {
				this.$wsc.getList(this.path + '/' + item.id + '/task', {}, tasks => {
					this.$set(item, 'tasks', tasks)
					this.computeItemsDates()
				},
				fail => {

				});
			},
			initDates(){
				let min_date = new Date('3000-12-12')
				let max_date = new Date('1970-01-01')

				for (let i = 0; i < this.items.length; i++) {
					let item = this.items[i]

					if (new Date(item.start) < min_date) {
						min_date = new Date(item.start)
					}
					if (item.end !== null){
						if (new Date(item.end) > max_date) {
							max_date = new Date(item.end)
						}
					}
					else {
						if (new Date(item.start) > max_date) {
							max_date = new Date(item.start)
						}
					}
				}

				let dates = {days: [], weeks: [], months: [], years: []}

				for (let key in dates){
					let startDate = this.$moment(min_date).format('YYYY-MM-DD hh:mm')
					let endDate = this.$moment(new Date(max_date)).add(1, key).format('YYYY-MM-DD hh:mm')

					while (this.$moment(startDate).isSameOrBefore(endDate)) {
						dates[key].push(this.computeDateData(startDate, key))
						startDate = this.$moment(new Date(startDate)).add(1, key).format('YYYY-MM-DD hh:mm')
					}
				}

				this.dates = dates
				this.ready--
			},
			labelDate(date, n){
				if (this.type === 'years')
					return this.$moment(date.start_date).add(n - 1, 'months').format('MMM YYYY')
				if (this.type === 'months' || this.type === 'weeks')
					return this.$moment(date.start_date).add(n - 1, 'days').format('LL')
				if (this.type === 'days')
					return this.$moment(date.start_date).format('DD MMM') + ' ' + this.$moment(date.start_date).add(n - 1, 'hours').format('kk:mm')
			},
			mouseEnter(e, sub, date){
				this.$set(this.tool_tip, 'x', e.clientX)
				this.$set(this.tool_tip, 'y', this.$refs.parent.getBoundingClientRect().top + 10)
				this.$set(this.tool_tip, 'sub', sub)
				this.$set(this.tool_tip, 'date', date)
			},
			savedTask(task){
				let index = this.items.findIndex(e => e.id === this.item2edit_id)
				let tasks = this.items[index].tasks
				let old_task = tasks.find(e => e.id === task.id)
				let task_index = tasks.findIndex(e => e.id === task.id)

				this.items[index].tasks.splice(task_index, 1, Object.assign({}, old_task, task))

				this.computeItemsDates(true)
			},
			setUpHooks(){
				this.events = new this.$NVEvent("Gantt")

				this.pevents.wait('new-item', (item) => {
					item['tasks'] = []
					this.items.unshift(item)
					this.computeItemsDates(true)
				})

				this.pevents.wait('saved-item', (item) => {
					let index = this.items.findIndex(e => e.id === item.id)
					let old_item = this.items.find(e => e.id === item.id)

					this.items.splice(index, 1, Object.assign({}, old_item, item))
					this.computeItemsDates(true)
				})

				this.pevents.wait('delete-items', success => {
					success.succeded.forEach(e => {
						let index = this.items.findIndex(l => l.id === e.id)
						this.items.splice(index, 1)
						this.totalItems = this.items.length

						let index2 = this.selected.findIndex(l => l.id === e.id)
						this.selected.splice(index2, 1)
					})
				})

				this.pevents.wait('delete-item', success => {
					let index = this.items.findIndex(e => e.id === parseInt(success.id))

					this.items.splice(index, 1)
				})

				this.pevents.wait('search', val => {

				})

				this.pevents.wait('search-clear', () => {

				})
			},
			scrollView(item){
				this.$refs.viewport.scrollLeft = item._position[this.type] * this.unit_width
			},
			zoomWheel(event){
				event.preventDefault();

				let width = this.unit_width
				width += event.deltaY * -2

				if(event.deltaY > 0)
					if(this.type !== 'days' && this.unit_width > 20)
						this.unit_width += event.deltaY * -2
					else if(this.type === 'days' && this.unit_width > 10)
						this.unit_width += event.deltaY * -2
				if(event.deltaY < 0 && this.unit_width < 300)
					this.unit_width += event.deltaY * -2

				setTimeout(() => {
					this.$nextTick(() => {
						this.dates_width = this.$refs.dates.clientWidth
					})
				}, 250)
			}
		},
		created(){
			this.fetchEntityItems()
			this.setUpHooks()
		}
	}
</script>